<template>
    <a 
        v-if="document.data.redirect_enabled"
        :href="`/out/${document.id}/`"
        target="_blank" 
        rel="nofollow"
    >
        <i class="fa fa-external-link-alt" /> {{ text }}
    
    </a>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        text: {
            type: String,
            required: false,
            default: 'Ansök om lån'
        }
    }
};
</script>