<template>
    <h1>404</h1>
</template>
<script>
export default {
    head() {
        return {
            title: '404'
        };
    }
};
</script>