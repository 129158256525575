import { size, keyBy} from 'lodash';
import { replace as replaceHelper, queryAll } from '@swegaming-ab/nuxtjs-helpers';

export const state = () => ({
    navigation: {},
    lenders: {},
    footer: {},
    lists: {},
    authors: {}
});

export const mutations = {
    SET_STATE(state, {key, value}) {
        state[key] = replaceHelper(value, state.currentPrismicLocale);
    }
};

export const actions = {
    async fetchStates({ commit }, {$prismic}) {
        try {

            if (size(this.state.lenders) === 0) {
                const lenders = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'lender'),
                    {}
                );

                commit('SET_STATE', {
                    key: 'lenders',
                    value: keyBy(lenders, 'id')
                });
            }

            if (size(this.state.navigation) === 0) {
                const navigation = await $prismic.api.getSingle('navigation', {});
                if (navigation) {
                    commit('SET_STATE', {
                        key: 'navigation',
                        value: navigation.data
                    });
                }
            }

            if (size(this.state.footer) === 0) {
                const footer = await $prismic.api.getSingle('footer', {});
                if (footer) {
                    commit('SET_STATE', {
                        key: 'footer',
                        value: footer.data
                    });
                }
            }

            if (size(this.state.lists) === 0) {
                const lists = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'list'),
                    {}
                );

                commit('SET_STATE', {
                    key: 'lists',
                    value: keyBy(lists, 'id')
                });
            }

            if (size(this.state.authors) === 0) {
                const authors = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'author'),
                    {}
                );

                commit('SET_STATE', {
                    key: 'authors',
                    value: keyBy(authors, 'id')
                });
            }

        }
        catch (e) {
            console.log(e);
        }
    }
};
