<template>
    <li :class="{show: showMobile}" class="nav-item dropdown dropdown-animate" data-toggle="hover">
        <a class="nav-link dropdown-toggle text-dark" @click="toggle()">{{ text }}</a>
        <div :class="{show: showMobile}" class="dropdown-menu dropdown-menu-lg dropdown-menu-arrow p-0">
            <ul class="list-group list-group-flush">
                <li v-for="dropDownItem in items" :key="dropDownItem.link_item.id" class="dropdown dropdown-animate" data-toggle="hover">
                    <nuxt-link :to="$prismic.linkResolver(dropDownItem.link_item)" class="list-group-item list-group-item-action" role="link" aria-haspopup="true" aria-expanded="false">
                        <div class="media d-flex align-items-center">
                            <figure style="width: 70px;">
                                <prismic-image :img="dropDownItem.link_icon" :alt="dropDownItem.link_text" h="40" class="lazy img-fluid pl-3 mr-5" />
                            </figure>
                            <div class="media-body ml-3">
                                <h6 class="mb-1 font-weight-bolder">{{ dropDownItem.link_text }}</h6>
                                <p class="mb-0">{{ dropDownItem.link_description }}</p>
                            </div>
                        </div>
                    </nuxt-link>
                </li>
            </ul>

            <div 
                class="delimiter-top py-3 px-4" 
                v-html="$prismic.asHtml(description)"
            />
        </div>
    </li>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        description: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showMobile: false
        };
    },
    watch: {
        '$route.path': {
            handler: function() {
                this.showMobile = false;
            }
        }
    },
    methods: {
        toggle() {
            this.showMobile = ! this.showMobile;
        }
    }
};
</script>