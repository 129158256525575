<template>
    <header id="header-main" :class="{'header-collapse-show': showMobile}" class="header header-transparent">
        <nav id="navbar-main" :class="{'bg-light navbar-collapsed': showMobile}" class="navbar navbar-main navbar-expand-lg navbar-light">
            <div class="container px-lg-0">
                <nuxt-link class="navbar-brand mr-lg-5" to="/">
                    <img id="navbar-logo" alt="Kreditly logo" src="/logo-blue.png" class="navbar-logo">
                </nuxt-link>

                <button class="navbar-toggler pr-0">
                    <i class="fas fa-bars" style="font-size:25px; margin-top: 5px;" @click="toggle()" />
                </button>

                <div id="navbar-main-collapse" :class="{show: showMobile}" class="navbar-collapse collapse">
                    <ul class="navbar-nav ml-lg-auto align-items-center"> 
                        <template v-for="(item, index) in $store.state.navigation.body">
                            <li v-if="item.slice_type === 'link'" :key="`nav${index}`" class="nav-item">
                                <nuxt-link 
                                    :to="$prismic.linkResolver(item.primary.link_item)"
                                    class="nav-link text-dark font-weight-bolder"
                                >
                                    {{ item.primary.link_text }}
                                </nuxt-link>
                            </li>
                            <dropdown 
                                v-else 
                                :key="`nav${index}`"
                                :items="item.items"
                                :text="item.primary.drop_down_text"
                                :description="item.primary.drop_down_description"
                            />
                        </template>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>
<script>
import Dropdown from '@/components/includes/navigation/Dropdown.vue';
export default {
    components: {
        Dropdown
    },
    data() {
        return {
            showMobile: false
        };
    },
    watch: {
        '$route.path': {
            handler: function() {
                this.showMobile = false;
            }
        }
    },
    methods: {
        toggle() {
            this.showMobile = ! this.showMobile;
        }
    }
};
</script>