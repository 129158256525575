<template>
    <footer id="footer-main ">
        <div class="footer footer-dark bg-gradient-dark">
            <div class="container">
                <div class="row pt-md">
                    <div class="col-lg-4 mb-5 mb-lg-0">
                        <nuxt-link to="/" class="d-block pb-4">
                            <img 
                                data-src="/logo-white.png" 
                                alt="Kreditly logo" 
                                class="lazy entered loaded" 
                                style="height: 40px;" 
                                data-ll-status="loaded" src="/logo-white.png"
                            >
                        </nuxt-link>
                        <p>Vår kunskap - <strong>Din makt.</strong> Kreditly samlar in och jämför information om totalt 94 låneerbjudanden på den svenska marknaden.</p>
                        <a href="https://www.dmca.com/Protection/Status.aspx?ID=63d24774-0a15-4bed-83dc-8ee0ca7f656f&amp;refurl=https://kreditly.se/" title="DMCA.com Protection Status" class="dmca-badge">
                            <img data-src="//images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=63d24774-0a15-4bed-83dc-8ee0ca7f656f" alt="DMCA.com Protection Status" class="lazy entered loaded" data-ll-status="loaded" src="//images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=63d24774-0a15-4bed-83dc-8ee0ca7f656f">
                        </a>
                        <script src="//images.dmca.com/Badges/DMCABadgeHelper.min.js" />
                        <a href="https://gnuheter.com/creeper/senaste" title="Creeper">
                            <img data-src="https://gnuheter.com/creeper/image" alt="Creeper" class="lazy entered loaded" width="80" height="15" border="0" data-ll-status="loaded" src="https://gnuheter.com/creeper/image">
                        </a>
                    </div>

                    <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
                        <h6 class="heading mb-3">Populära sidor</h6>
                        <ul class="list-unstyled">
                            <li v-for="link in $store.state.footer.popular_col_1" :key="link.document.id">
                                <nuxt-link :to="$prismic.linkResolver(link.document)">{{ link.text }}</nuxt-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                        <h6 class="heading mb-3">&nbsp;</h6>
                        <ul class="list-unstyled text-small">
                            <li v-for="link in $store.state.footer.popular_col_2" :key="link.document.id">
                                <nuxt-link :to="$prismic.linkResolver(link.document)">{{ link.text }}</nuxt-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-sm-4 mb-5 mb-lg-0">
                        <h6 class="heading mb-3">Läsvärt</h6>
                        <ul class="list-unstyled">
                            <li v-for="link in $store.state.footer.read_whorty" :key="link.document.id">
                                <nuxt-link :to="$prismic.linkResolver(link.document)">{{ link.text }}</nuxt-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-sm-4 mb-5 mb-lg-0">
                        <h6 class="heading mb-3">Kreditly</h6>
                        <ul class="list-unstyled">
                            <li v-for="link in $store.state.footer.kreditly" :key="link.document.id">
                                <nuxt-link :to="$prismic.linkResolver(link.document)">{{ link.text }}</nuxt-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row align-items-center justify-content-md-between py-4 mt-4 delimiter-top">
                    <div class="col-md-6">
                        <div class="copyright text-sm font-weight-bold text-center text-md-left">
                            © 2022 Kreditly. Alla rättigheter förbehållna.
                            <div class="row">
                                <div class="col-lg-12 pt-3">
                                    Kreditly<br>
                                    Stora Badhusgatan 18<br>
                                    411 21 Göteborg
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0 position-absolute">
                            <li class="nav-item">
                                <a class="nav-link" href="https://twitter.com/kreditly" target="_blank" rel="noopener">
                                    <i class="fab fa-twitter" />
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://www.facebook.com/kreditly" target="_blank" rel="noopener">
                                    <i class="fab fa-facebook" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col pb-5">
                        <a href="https://www.minskaco2.se/crt/dispcust/c/5308/l/3" target="_blank" rel="noopener">
                            <img data-src="/co2.png" class="lazy entered loaded" alt="Kreditly.se är en co2-neutral webbplats" data-ll-status="loaded" src="/co2.png">
                        </a>
                        <img data-src="comodo.png" class="lazy entered loaded" alt="Säkrad av Comodo SSL" data-ll-status="loaded" src="/comodo.png">
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {};
</script>
