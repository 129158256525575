import Vue from 'vue';

import { 
    PrismicImage, 
} from '@swegaming-ab/vue-components';

import OutLink from '@/components/OutLink.vue';

const components = {
    PrismicImage,
    OutLink
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});
