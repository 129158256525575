<template>
    <div>
        <Navigation />
        <nuxt />
        <client-only>
            <CookieConsent
                content="Den här webbplatsen använder cookies för besöksstatistik och för att förbättra användarupplevelsen för våra besökare."
                button-text="Jag förstår"
            />
        </client-only>

        <SiteWideLinks />
        <Footer />
    </div>
</template>
<script>
import Footer from '@/components/includes/TheFooter.vue';
import SiteWideLinks from '@/components/includes/TheSiteWideLinks.vue';
import Navigation from '@/components/includes/TheNavigation.vue';
import { CookieConsent } from '@swegaming-ab/vue-components';
export default {
    components: {
        CookieConsent,
        Footer,
        Navigation,
        SiteWideLinks
    },
    async middleware({ store, $prismic, route }) {
        await store.dispatch('fetchStates', { $prismic, route });
    }
};
</script>
