export default function (document, withDomain = false) {
    try {

        const documentsMap = require('@/documents.json');
        const documentHelper = documentsMap[document.id];
        const slug = documentHelper.slug;

        if (withDomain) {
            return `https://kreditly.se${slug}`;
        }

        return slug;
    }
    catch (e) {
        return '/undefined';
    }
}