// eslint-disable-next-line no-unused-vars
export default ({ app }, inject) => {
    inject('getOrderedList', getOrderedList);
    inject('getCreditCheckString', getCreditCheckString);
};

const getCreditCheckString = (creditCheck) => {
    return {
        'creditsafe': 'Creditsafe',
        'bisnode': 'Bisnode',
        'safenode': 'Safenode',
        'uc': 'UC'
    }[creditCheck] || 'unknown';
};

const getOrderedList = (list, allLenders, modelClass) => {

    let arr = [];
    let maxSalesCount = 0;
    let maxConversionRate = 0;
    let maxEpc = 0;

    list.forEach(l => {
        const lender = allLenders[l.id];
        lender.model = new modelClass(lender);


        maxEpc = lender.model._core.sales_stats.epc > maxEpc ? lender.model._core.sales_stats.epc : maxEpc;
        maxSalesCount = lender.model._core.sales_stats.sales > maxSalesCount ? lender.model._core.sales_stats.sales : maxSalesCount;
        maxConversionRate = lender.model._core.sales_stats.conversion_rate > maxConversionRate ? lender.model._core.sales_stats.conversion_rate : maxConversionRate;

        arr.push(lender);
    });

    arr.forEach(lender => {
        lender.order =
            (8 * (lender.model._core.sales_stats.epc / maxEpc)) +
            lender.model._core.sales_stats.sales / maxSalesCount +
            lender.model._core.sales_stats.conversion_rate / maxConversionRate;
    });

    return arr.slice(0).sort((a, b) => b.order - a.order);
};